window.__scripdirs__ = null;
window.cmindex = {};
window.cm = {};
window.cm_registerpromises = {};

window.sleep = async function (interval) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, interval);
    });
};

window.cm.fetchClass = async function fetchClass(name, sd) {
    let cidx = window.cmindex[sd + ":" + name];
    if (!cidx) {
        // eslint-disable-next-line no-async-promise-executor
        let p = new Promise(async (resolve, reject) => {
            let __script = null;
            let ___f = null;
            try {
                __script = await $.get({url: '/webstatic/' + sd + '/ecommerce/lib/js/components/' + name + '.js', dataType:'text'});
            } catch (err) {
                window.cmindex[sd + ":" + name] = {v:null};
                resolve(null);
                return;
            }
            try {
                ___f = Function(`return new Promise(async function (resolve, reject) {
                    cm_registerpromises["${sd}:${name}"] = resolve;
                    var __filename = "${sd}";
                    ${__script};
            })`);
                window.f = ___f;
            } catch (err) {
                console.error(err);
                window.cmindex[sd + ":" + name] = {v:null};
                resolve(null);
                return;
            }
            try {
                await ___f();
                resolve(window.cmindex[sd + ":" + name].v);
            } catch (err) {
                console.error(err);
            }
        });
        window.cmindex[sd + ":" + name] = {p: p};
        return p;
    } else if (cidx.p) {
        return cidx.p;
    }
    return cidx.v;
};

window.cm.getClass = async function (name) {
    let c = window.cm[name];
    if (c) return c;
    if (c === null) return null;
    if (!window.__scriptdirs__) {window.__scriptdirs__ = (await $.ajax({url:'/ecommerce/scriptdirs'})).reverse();}
    for (let i = 0; i < window.__scriptdirs__.length; i++) {
        let sdname = window.__scriptdirs__[i];
        let cidx = window.cmindex[sdname + ":" + name];

        if (!cidx || cidx.p) {
            c = await window.cm.fetchClass(name, sdname);
        } else {
            c = window.cmindex[sdname + ":" + name].v;
        }
        if (c) {
            window.cm[name] = c;
            return c;
        }
        //if (c === null) continue; redundant
    }
    return null;
};

window.cm.SuperClass = async function SuperClass({name, inherits, filename}) {
    let sd = filename;
    if (!window.__scriptdirs__) {window.__scriptdirs__ = (await $.ajax({url:'/ecommerce/scriptdirs'})).reverse();}
    let sdidx =  window.__scriptdirs__.indexOf(sd);
    let c = null;
    for (let i = sdidx + 1; i < window.__scriptdirs__.length; i++) {
        let sdname = window.__scriptdirs__[i];
        let cidx = window.cmindex[sdname + ":" + sdname];
        if (!cidx || cidx.p) {
            c = await window.cm.fetchClass(name, sdname);
        } else {
            c = cidx.v;
        }
        if (c) {
            return c;
        }
    }

    for (let i = 0; i < window.__scriptdirs__.length; i++) {
        let sdname = window.__scriptdirs__[i];
        let cidx = window.cmindex[sdname + ":" + inherits];
        if (!cidx || cidx.p) {
            c = await window.cm.fetchClass(inherits, sdname);
        } else {
            c = cidx.v;
        }
        if (c) {
            return c;
        }
    }
    return null;
};

class Component {

    static name() {
        throw "NAME NOT DEFINED";
    }

    static getId() {
        if (!Component.id) {
            Component.id = 0;
        }
        return Component.id++;
    }

    static openInModal(props) {
        let level = Component.ModalLevel++;
        let id = this.getId();
        let self = this;
        return new Promise((resolve, reject) => {
            let fullid = `modal-${id}`;
            let pwelement;

            pwelement = $(`<div style="z-index: ${level}" id="${fullid}" class="modal-mask" @click="resolve()">
                        <div class="modal is-active">
                           <div class="modal-background"></div>
                              <div class="modal-card">                                
                                <section class="modal-card-body" @click="$event.stopPropagation()">
                                  <h5 class="">{{tr(props.header)}}</h5>  
                                  <${self.componentName()} v-bind="props" @resolve="resolve"></${self.componentName()}>
                                </section>                                  
                                </footer>                             
                              </div>        
                        </div>               
                </div>`);


            $(document.body).append(pwelement);

            new Vue({
                data: function () {
                    return {
                        props: props,
                        tr: window.tr

                    };
                },
                el: pwelement.get(0),
                components: {},
                mounted: function () {
                },
                methods: {
                    resolve: function (value) {
                        this.$destroy();
                        let element = document.getElementById(fullid);
                        if (element) {
                            element.remove();
                        }
                        Component.ModalLevel--;
                        resolve(value);


                    }
                }


            });
        });

    }

    static getMutatiosDict() {
        return {};
    }

    static async loadData() {
        return {};
    }

    static save(key, value) {
        let obj = {
            module: this.name(),
            key: key,
            value: value,
        };
        window.$store.dispatch('save', obj);
    }

    static get(key) {
        let obj = {
            module: this.name(),
            key: key,
        };
        return window.$store.getters.getState(obj);
    }

    static componentName() {
        return null;
    }

    type() {
        return "component";
    }

    constructor() {
        //console.log(`${this.constructor.componentName()} was loaded succesfully`)
    }

    getRoutes() {
        return {};
    }

    data() {
        return {};
    }

    getData() {
        let self = this;
        return function () {
            return self.data();
        };
    }

    template() {
        return "<h1>TEMPLATE IS NOT DEFINED</h1>";
    }

    title() {
        return "";
    }

    getTemplate() {
        if (this.title() != "") {
            return `<div>
                      <section class="section main-section">
                          <h1 class="main-title">${this.title()}</h1>
                          ${this.template()}
                      </section>
                  </div>`;
        } else {
            return `<div>
                    <section class="section main-section">
                        ${this.template()}
                    </section>
                </div>`;
        }
    }

    mounted() {
        return async function () {
            return null;
        };
    }

    created() {
        return async function () {
        };
    }

    updated() {
        return async function () {
        };
    }

    beforeDestroy(){
        return function () {
            return true;
        };
    }


    getMethods() {
        return {};
    }

    getProps() {
        return [];
    }

    useProps() {
        return this.getProps().length > 0;
    }

    getComputed() {
        return {};
    }

    getInheritAttrs(){
        return true;
    }

    getComponent() {
        return {
            inheritAttrs:this.getInheritAttrs(),
            props: this.getProps(),
            data: this.getData(),
            computed: this.getComputed(),
            methods: this.getMethods(),
            watch: this.getWatch(),
            created: this.created(),
            updated: this.updated(),
            mounted: this.mounted(),
            components: this.getComponents(),
            template: this.getTemplate(),
            beforeDestroy:this.beforeDestroy()
        };
    }

    getComponents() {
        return {};
    }

    getCards() {
        return [];
    }

    getWatch() {
        return {};
    }

    static registerComponent(customerName = null) {
        let cmpName = customerName;
        if (!cmpName)
            cmpName =  this.componentName();
        if (!cmpName) {
            console.error('El componente no se puede registrar globalmente, no posee nombre definido');
            throw 'El componente no se puede registrar globalmente, no posee nombre definido';
        }
        else {
            let componentClass = this;
            Vue.component(this.componentName(), new componentClass().getComponent());
            if(Vue.config.devtools)
                console.log(`${this.componentName()} - registered succesfully`); // eslint-disable-line no-console
            else
                console.log=function(){return null};
        }
    }

    static initClass(description) {
        let {name, filename, sd} = description;
        this.__description__ = description;
        this.__super__ = Reflect.getPrototypeOf(this);
        sd = sd || filename;
        if (!name || !sd) throw new Error('invalid description arguments: name or sd');
        window.cmindex[sd + ':' + name] = {v: this};
        if (window.cm_registerpromises[sd + ':' + name]) {
            window.cm_registerpromises[sd + ':' + name]();
            delete window.cm_registerpromises[sd + ':' + name];
        }

    }
}

Component.initClass({name: 'Component', inherits: null, sd: 'ecommerce'});
Component.id = 0;
Component.ModalLevel = 2000;

